.app {
    display: flex;
    height: 100vh;
}

.sidebar {
    width: 200px;
    background-color: #f0f0f0;
    padding: 20px;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
}

.content-area {
    flex-grow: 1;
    padding: 20px;
    overflow-y: auto;
}

.menu-button {
    display: block;
    width: auto;
    padding: 10px;
    margin-bottom: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    text-align: left;
    text-decoration: none;
    position: relative;
    overflow: hidden;
}

.menu-button::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 0;
    right: 0;
    height: 10px;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0.2), transparent);
    transform: skew(-45deg);
}

.menu-button:hover {
    background-color: #0056b3;
}

.menu-button:active {
    background-color: #004085;
}

.sub-menu {
    margin-left: 20px;
}

.sub-menu-button {
    display: block;
    width: auto;
    padding: 5px 10px;
    margin-bottom: 5px;
    background-color: #e9ecef;
    border: none;
    border-radius: 3px;
    text-align: left;
    text-decoration: none;
}

.sub-menu-button:hover {
    background-color: #ced4da;
}

.sub-menu-button:active {
    background-color: #adb5bd;
}

.content {
    max-width: 800px;
    margin: 0 auto;
}

.content img {
    max-width: 500px;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.content img:hover {
    transform: scale(1.03);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}